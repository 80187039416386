import ReactGA from "react-ga4";

export const initGA = (trackingCode) => {
  ReactGA.initialize(trackingCode);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
