// A schema is a collection of type definitions (hence "typeDefs")
// that together define the "shape" of queries that are executed against
// your data.
const CreateEmailTypes = `#graphql
  # Comments in GraphQL strings (such as this one) start with the hash (#) symbol.

  # This "ContactMessage" type defines the queryable fields for every contact message.
  type ContactMessage {
    firstName: String!
    lastName: String!
    email: String!
    prefix: String!
    phone: String!
    company: String!
    jobRole: String!
    country: String!
    productRequired: String!
    hearing: String!
    hearingOption: String
    message: String!
    size: String!
    receiveNewsletters: Boolean!
  }

  type Response {
    message: String
  }

  # The "Query" type is special: it lists all of the available queries that
  # clients can execute, along with the return type for each. In this
  # case, the "contactMessages" query returns an array of zero or more ContactMessages (defined above).
  type Query {
    contactMessages: [ContactMessage]
  }
  type Mutation {
    sendContactMessageNew(
      firstName: String!
      lastName: String!
      email: String!
      prefix: String!
      phone: String!
      company: String!
      jobRole: String!
      country: String!
      productRequired: String!
      hearing: String!
      hearingOption: String
      message: String!
      size: String!
      receiveNewsletters: Boolean!
    ): Response
  }
`;

export default CreateEmailTypes;
