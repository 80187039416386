import validator from 'validator';
import freeEmailDomains from '../../lib/sitelists/freeEmailDomains';
import { updateNewsletterSubscription } from '../../server/mailchimp';

const CreateEmailResolvers = {
  Query: {
    contactMessages: () => {
      return 'THis was successfully sent';
    },
  },
  Mutation: {
    sendContactMessageNew: async (_, args) => {
      if (!args.email || !validator.isEmail(args.email)) {
        throw new Error('Please enter a valid email address');
      }
      const domain = args.email.split('@').pop();
      if (freeEmailDomains.includes(domain)) {
        throw new Error('Please use your business email address');
      }
      if (!args.message || args.message.trim().length === 0) {
        throw new Error('Please enter your feedback');
      }
      if (!args.company || args.company.trim().length === 0) {
        throw new Error('Please enter your company name');
      }
      if (args.email.trim() === 'pentest01@aptive.co.uk') {
        return true;
      }
      try {
        if (args.type === 'Degreed integration inquiry') {
          const slackMessage = `Received from contact form:\n\n*Email address:* ${args.email}\n*Phone:* ${args.prefix}${args.phone}\n*First Name:* ${args.firstName}\n*Last Name:* ${args.lastName}\n*Company:* ${args.company}\n*Audience size:* ${args.size}\n*Job Role:* ${args.jobRole}\n*Country:* ${args.country}\n*Product Required:* ${args.productRequired}\n*Where did you hear about us?* ${args.hearing}\n*Hearing Option:* ${args.hearingOption}\n*Message:*\n${args.message}\n`;
          await fetch(process.env.DEGREED_WEBHOOK_URL, {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              text: slackMessage,
            }),
          }).then((response) => response.json());
        }
        const createResponse = await fetch('https://api.intercom.io/users', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer dG9rOmE2ZDAwZWU3XzEyOWRfNDJmM19hZmRmX2NlMTE1ZGExYzI2MDoxOjA=',
          },
          body: JSON.stringify({
            email: args.email,
          }),
        }).then((response) => response.json());
        if (args.receiveNewsletters) {
          let { status, statusCode } = await updateNewsletterSubscription({
            email: args.email,
            subscribe: true,
          });
        }
      } catch (err) {
        throw new Error(
          'Sorry there was an error, please try again or email us directly at team@anderspink.com'
        );
      }
      return { message: 'Success' };
    },
  },
};

export default CreateEmailResolvers;
