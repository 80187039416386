import App from 'next/app';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { mode } from '@chakra-ui/theme-tools';
import '../assets/css/style.css';
import { createContext, useEffect } from 'react';
import { fetchAPI } from '../lib/api';
import { getStrapiMedia } from '../lib/media';
import store from '../redux/form';
import { initGA, logPageView } from '../lib/analytics';
import { useApollo } from '../apollo/client';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/globals.css';

import 'react-multi-carousel/lib/styles.css';

declare global {
  interface Window {
    GA_INITIALIZED: boolean;
    gtag: any; // Add this line to declare the gtag function
  }
}

const overrides = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        color: mode('#202124', 'gray.200')(props),
        bg: mode('white', '#202124')(props),
      },
    }),
  },
});

// Store Strapi Global object in context
export const GlobalContext = createContext({});

const MyApp = ({ Component, pageProps }) => {
  const { global } = pageProps;

  let apolloClient = useApollo(store);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA('G-QCBSFWE5R6');
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={overrides}>
          <Head>
            <link
              rel="shortcut icon"
              href={getStrapiMedia(global.attributes.favicon)}
            />
          </Head>
          <GlobalContext.Provider value={global.attributes}>
            <Component {...pageProps} />
          </GlobalContext.Provider>
        </ChakraProvider>
      </ApolloProvider>
    </Provider>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (ctx) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);
  // Fetch global site settings from Strapi
  const globalRes = await fetchAPI('/global', {
    populate: {
      favicon: '*',
      defaultSeo: {
        populate: '*',
      },
      andersPink: {
        populate: '*',
      },
    },
  });
  // Pass the data to our page via props
  return { ...appProps, pageProps: { global: globalRes.data } };
};

export default MyApp;
