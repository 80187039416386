import { getStrapiURL } from "./api";

export function getStrapiMedia(media) {
  const url = media?.data?.attributes?.url || media?.data?.attributes?.picture;
  let imageUrl = "";
  if (url) {
    imageUrl = url.startsWith("/") ? getStrapiURL(url) : url;
  }
  return imageUrl;
}
