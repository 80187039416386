import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { SchemaLink } from '@apollo/client/link/schema';
import { schema } from './schema';
import merge from 'deepmerge';

let apolloClient;

// Strapi GraphQL Implementation
const httpLinkStrapi = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/graphql`,
});

// AP GraphQL Implementation
const httpLinkAp = new HttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

const authLink = new ApolloLink((operation, forward) => {
  const token = process.env.NEXT_PUBLIC_API_KEY;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

function createIsomorphLink() {
  if (typeof window === 'undefined') {
    return new SchemaLink({ schema });
  } else {
    return httpLinkAp.concat(
      setContext(() => ({
        clientName: 'ap',
      }))
    );
  }
}

function createApolloClient() {
  const splitLink = split(
    (operation) => {
      return operation.getContext().clientName === 'strapi';
    },
    authLink.concat(httpLinkStrapi),
    authLink.concat(createIsomorphLink())
  );

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: splitLink,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
